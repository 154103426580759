.career_main_image {
  position: relative;

}

.career_main_section {
  overflow: hidden;
}

.career_main_image img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.career_main_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.career_main_overlay_text {
  color: white;
  font-size: 70px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}


.recomanded-jobs-section {
  padding: 50px 50px 0px 82px;
}


.recomanded-icons {
  border: 2px solid #0957ad;
  font-style: italic;
  text-align: center;
  font-size: 15px;
  padding: 2px;
  border-radius: 100px;
  background-color: white;
  color: #0957ad;
  font-weight: 700;
  width: 160px;
  margin-left: 6px;
  font-family: Inter var, sans-serif;
}


.recomanded_service_shorts {
  margin-top: 10px;
  font-size: 30px;
  font-weight: 700;
  font-family: Inter var, sans-serif;
}

.recomanded_service_shorts span:last-of-type {
  margin-top: -10px;
  display: block
}

.recomanded_service_shorts_discription {
  font-size: 15px;
  font-family: Inter var, sans-serif;
  margin-top: 10px;
}

.job-categories-section {
  position: relative;
  margin-top: 60px;
  background-color: #f8f8f8;
  width: 100%;
  height: 130vh;
}

.job-categories {
  position: absolute;
  font-family: Inter var, sans-serif;
  font-weight: 700;
  color: #0957ad;
  background-color: white;
  left: 5%;
  top: 7%;
  padding: 10px 100px 10px 105px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  font-size: 20px;
  border: 3px solid #0957ad;
  border-left: 3px solid #0957ad;
  border-right: 3px solid #0957ad;
  border-radius: 5px;
}

.techincal-support-categories {
  cursor: pointer;
  position: absolute;
  font-family: Inter var, sans-serif;
  font-weight: 700;
  color: #6D767d;
  background-color: white;
  left: 5%;
  top: 15%;
  padding: 12px 140px 12px 50px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  font-size: 15px;
  border-radius: 5px;
}

.career-job-item {
  position: relative;
  display: flex;
  align-items: center;
  left: -30px;
}

.career-job-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #0957ad;
  border-radius: 5px;
  padding: 5px;
  margin-right: 10px;
}

.job-title {
  font-size: 1em;
  color: #6D767d;
}


.graphic-design-categories {
  cursor: pointer;
  position: absolute;
  font-family: Inter var, sans-serif;
  font-weight: 700;
  color: #6D767d;
  background-color: white;
  left: 5%;
  top: 24%;
  padding: 12px 150px 12px 50px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  font-size: 15px;
  border-radius: 5px;
}

.ui-categories {
  cursor: pointer;
  position: absolute;
  font-family: Inter var, sans-serif;
  font-weight: 700;
  color: #6D767d;
  background-color: white;
  left: 5%;
  top: 33%;
  padding: 12px 158px 12px 50px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  font-size: 15px;
  border-radius: 5px;
}

.mobile-app-categories {
  cursor: pointer;
  position: absolute;
  font-family: Inter var, sans-serif;
  font-weight: 700;
  color: #6D767d;
  background-color: white;
  left: 5%;
  top: 42%;
  padding: 12px 81px 12px 50px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  font-size: 15px;
  border-radius: 5px;
}

.website-app-categories {
  cursor: pointer;
  position: absolute;
  font-family: Inter var, sans-serif;
  font-weight: 700;
  color: #6D767d;
  background-color: white;
  left: 5%;
  top: 51%;
  padding: 12px 103px 12px 50px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  font-size: 15px;
  border-radius: 5px;
}

.ecommerce-categories {
  cursor: pointer;
  position: absolute;
  font-family: Inter var, sans-serif;
  font-weight: 700;
  color: #6D767d;
  background-color: white;
  left: 5%;
  top: 60%;
  padding: 12px 172px 12px 50px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  font-size: 15px;
  border-radius: 5px;
}

.career-job-card {
  overflow: hidden;
  position: relative;
  left: 40%;
  top: 15%;
  width: 50%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  background-color: white;
  border-left: 5px solid #0957ad;
  border-radius: 12px;
  height: 25%;
}

.career-job-card-2 {
  overflow: hidden;
  position: relative;
  left: 40%;
  top: 19%;
  width: 50%;
  background-color: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  border-left: 5px solid #0957ad;
  border-radius: 12px;
  height: 25%;
}

.speaker-img {
  padding: 5px;
  width: 45px;
  border: 2px solid #0957ad;
  border-radius: 5px;
  position: absolute;
  left: 20px;
  top: 10px;
}

.carrer-type {
  position: absolute;
  left: 90px;
  top: 15px;
}

.carrer-type b {
  color: #0957ad;
}

.carrer-time {
  position: absolute;
  left: 210px;
  top: 15px;
}

.carrer-time b {
  color: #0957ad;
}

.carrer-experience {
  position: absolute;
  left: 385px;
  top: 15px;
}

.carrer-experience b {
  color: #0957ad;
}

.carrer-hiring-open {
  position: absolute;
  left: 630px;
  background-color: #0957ad;
  padding: 10px 20px 10px 13px;
  border-bottom-left-radius: 15px;
  color: white;
  font-family: Inter var, sans-serif;
  font-weight: 700;
}

.career-requirements {
  position: absolute;
  top: 60px;
  text-align: justify;
  width: 95%;
  left: 20px;
}

.career-requirements span {
  color: #0957ad;
  font-family: Inter var, sans-serif;
  font-weight: 700;
}

.career-requirements p {
  font-size: 14px;
}


.career-section-apply {
  position: relative;
  top: 170px;
  background-color: #Ceddec;
  padding: 25px;
}

.job-title-section {
  position: absolute;
  top: 10px;
  color: #0957ad;
  font-family: Inter var, sans-serif;
  font-weight: 700;
}

.career-salary-section {
  margin-top: 10px;
}

.career-location {
  position: absolute;
  left: 300px;
  top: 20px;
}

.career-location b {
  color: #0957ad;
}

.carer-apply-now-button {
  position: absolute;
  left: 80%;
  top: 15px;
  padding: 8px 20px 8px 20px;
  border-radius: 5px;
  border: 1px solid #6D767d;
  color: white;
  background-color: #0957ad;
  font-style: italic;
}

.career-benefits-section {
  padding: 40px 0px 0px 90px;
}


.joinus-careers {
  font-family: Inter var, sans-serif;
  font-weight: 700;
  font-size: 35px;
  margin-left: -10px;
}

.joinus-careers p {
  margin-top: -10px;
}

.joinus-careers-first {
  font-size: 17px;
  width: 30.333333%;
  margin-left: 75px;
  margin-top: 10px;
  font-family: Inter var, sans-serif;
  text-align: justify;
}

.joinus-careers-second {
  font-size: 17px;
  width: 30.333333%;
  margin-left: 40px;
  margin-top: 10px;
  font-family: Inter var, sans-serif;
  text-align: justify;
}


.benefits-carrer-cards .firstCardCol {
  margin-left: -20px;
}


.benefits-carrer-cards .firstCardCols {
  width: 31%;
  margin-left: 38px;
}


.benefits-carrer-cards .firstCardColss {
  width: 30%;
  margin-left: 30px;
}


.benefits-carrer-cards {
  padding: 10px 10px 50px 10px;
}

.applynow-form {
  bottom: 20px;
  width: 91%;
  padding: 10px;
  position: relative;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.apply-now-form-image {
  width: 80%;
}

.applynow-form form {
  position: absolute;
  top: 10%;
  left: 35%;
}

.input-fields {
  border: none;
  border-radius: 10px;
  border-bottom: 3px solid #0957ad;
  box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.5);
  width: 310px;
  padding: 10px;
  background-color: #F2f2f2;
}

.input-fields:focus {
  outline: none;
  border: none;
  border-bottom: 3px solid #0957ad;
}

.career-emails {
  position: absolute;
  left: 400px;
  top: 0px;
}

.career-phones {
  position: absolute;
  top: 80px;
}

.career-positions {
  position: absolute;
  left: 400px;
  top: 80px;
}

.career-positions select {
  width: 310px;
}

.input-fields::-webkit-outer-spin-button,
.input-fields::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.career-attachments {
  position: absolute;
  top: 160px;
}

.career-portfolio {
  position: absolute;
  left: 400px;
  top: 160px;
}

.custom-file-label {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

.attachment-icon {
  margin-right: 10px;
}

.career-message {
  position: absolute;
  top: 240px;
}

.career-message textarea {
  width: 710px;
  resize: none;
}

.career-apply-form-button button{
  top: 380px;
  width: 200px;
  /* padding: 10px 50px 10px 30px; */
}