.mainPage img {
  max-width: 100%;
  height: auto;
}

.mainPage {
  position: relative;
}

.overlayText {
  position: absolute;
  top: 30%;
  color: white;
  left: 4%;
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  font-family: Inter var, sans-serif;
}

.businesHead{
    color: white;
    font-size: 70px;
    font-weight: 700;
}

.businesHead p{
    color: white;
    font-size: 70px;
    font-weight: 700;
}


.bannerDescription{
    font-size: 17px;
    margin-top: 10%;
    font-style: normal;
    font-weight: 400;
    font-family: Inter var, sans-serif;
}


.bannerContactBtn{
    margin-top: 10%;
    color: #0957a2;
    font-style: italic;
    font-weight: 700;
    font-family: Inter var, sans-serif;
    padding: 15px 40px 15px 30px;
    border-radius: 10px;
    border:1px solid #6D767D;
}




.buttonContainer {
  display: flex;
  align-items: center; /* Align items vertically */
}

.bannerContactBtn {
  background-color: transparent;
  border: 2px solid white; /* First border */
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.bannerContactBtn:hover {
  background-color: white;
  color: black;
}

.playButtonOuterBorder {
  width: 70px; 
  height: 70px; 
  border: 1px solid #4681B9;
  border-radius: 50%;
  margin-left: 50px;
  margin-top: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playButtonMiddleBorder {
  width: 54px;
  height: 54px;
  border: 1px solid #4681B9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playButtonInnerBorder {
  width: 38px;
  height: 38px;
  border: 1px solid white;
  border-radius: 50%;
  background-color: white;
  box-sizing: border-box;
  padding: 10px;
}

.playButtonIcon {
  fill: #0957a2;
  font-size: 20px;
  transform: translate(-5%, -50%);
}