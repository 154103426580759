.service_main_image {
  position: relative;

}

.service_main_image img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.service_main_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.service_main_overlay_text {
  color: white;
  font-size: 70px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}


.service_graphimage {
  /* overflow: hidden;/ */
  position: relative;
}

.service_graphimage img {
  width: 100%;
}

.service_service_icon {
  position: absolute;
  top: 10%;
  left: 3%;
  border: 2px solid #0957ad;
  font-style: italic;
  text-align: center;
  font-size: 15px;
  padding: 2px;
  border-radius: 100px;
  background-color: white;
  color: #0957ad;
  font-weight: 700;
  width: 150px;
  margin-left: 6px;
  font-family: Inter var, sans-serif;
}

.service_process_icon {
  position: absolute;
  top: 13%;
  left: 3%;
  font-weight: 700;
  font-size: 30px;
  margin-top: 15px;
  font-family: Inter var, sans-serif;
}


.service_process_description{
  position: absolute;
  top: 16%;
  left: 25%;
  font-size: 17px;
  text-align: -webkit-center;
}


.first_circle_description{
  position: absolute;
  top:50%;
  left: 16%;
  font-size: 20px;
  font-weight: 700;
  font-family: Inter var, sans-serif;
}


.first_circle_description span:last-of-type{
  display: block;
  font-size: 17px;
  margin-top: 15px;
  margin-left: -9px;
  font-weight: 200;
  width: 180px;
  text-align: -webkit-center;
}

.second_circle_description{
  position: absolute;
  top:58%;
  left: 45%;
  font-size: 20px;
  font-weight: 700;
  font-family: Inter var, sans-serif;
}



.second_circle_description span:last-of-type{
  display: block;
  font-size: 17px;
  margin-top: 15px;
  margin-left: -17px;
  font-weight: 200;
  width: 180px;
  text-align: -webkit-center;
}

.third_circle_description{
  position: absolute;
  top:50%;
  left: 73%;
  font-size: 20px;
  font-weight: 700;
  font-family: Inter var, sans-serif;
}



.third_circle_description span:last-of-type{
  display: block;
  font-size: 17px;
  margin-top: 15px;
  margin-left: -17px;
  font-weight: 200;
  width: 180px;
  text-align: -webkit-center;
}