.client_section {
    padding: 50px 12px 0px 80px;
    margin: 0;
}

.client_section_icons {
    border: 2px solid #0957ad;
    font-style: italic;
    text-align: center;
    font-size: 15px;
    padding: 2px;
    border-radius: 100px;
    background-color: white;
    color: #0957ad;
    font-weight: 700;
    width: 110px;
    margin-left: -10px;
    font-family: Inter var, sans-serif;
}

.clientIntros {
    margin-top: 10px;
    font-size: 35px;
    font-weight: 700;
    font-family: Inter var, sans-serif;
}

.clientIntrosImage {
    margin-top: -60px;
}


.clientIntrosSecondSection {
    margin-top: -40px;
}

.clientsCounters {
    top: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clientIntrosSecondSection {
    width: 80%;
}

.total_projects {
    position: absolute;
    left: 17%;
    top: 10%;
    color: white;
}

.total_projects p {
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    font-family: Inter var, sans-serif;
}

.total_projects hr {
    margin-top: -20px;
    opacity: 1;
    border: 2px solid #6D767D;
    border-radius: 2px;
    width: 55%;
    margin-left: 35px;
}

.total_projects span {
    display: block;
    margin-left: 15px;
    font-style: normal;
    font-weight: 700;
    font-family: Inter var, sans-serif;
}

.happy_clients {
    position: absolute;
    left: 47%;
    top: 10%;
    color: white;
}

.happy_clients p {
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    font-family: Inter var, sans-serif;
}

.happy_clients hr {
    margin-top: -20px;
    opacity: 1;
    border: 2px solid #6D767D;
    border-radius: 2px;
    width: 70%;
    margin-left: 10px;
}

.happy_clients span {
    display: block;
    font-style: normal;
    font-weight: 700;
    font-family: Inter var, sans-serif;
}

.great_reviews {
    position: absolute;
    left: 74%;
    top: 10%;
    color: white;
}

.great_reviews p {
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    font-family: Inter var, sans-serif;
}

.great_reviews hr {
    margin-top: -20px;
    opacity: 1;
    border: 2px solid #6D767D;
    border-radius: 2px;
    width: 70%;
    margin-left: 10px;
}

.great_reviews span {
    display: block;
    font-style: normal;
    font-weight: 700;
    margin-left: -5px;
    font-family: Inter var, sans-serif;
}


.testimonail {
    position: relative;
    padding: 120px 0px 0px 0px;
}



.testimonal_icons_section {
    position: absolute;
    left: 80px;
    top: 180px;
}


.testimonal_icons_section_child {
    border: 2px solid #0957ad;
    font-style: italic;
    text-align: center;
    font-size: 15px;
    padding: 2px;
    border-radius: 100px;
    background-color: white;
    color: #0957ad;
    font-weight: 700;
    width: 150px;
    margin-left: 6px;
    font-family: Inter var, sans-serif;
}

.testimonal_icons_section_child_second {
    font-weight: 700;
    font-size: 26px;
    margin-top: 15px;
    font-family: Inter var, sans-serif;
}

.testimonal_icons_section_child_second span:last-of-type {
    display: block;
    margin-top: -10px;
}




.testimonal_square_box {
    position: absolute;
    z-index: 2;
    top: 350px;
    left: 130px;
    width: 300px;
    height: 300px;
    background-color: white;
    border: 2px solid #0957ad;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle {
    position: absolute;
    left: 15px;
    top: 20px;
    width: 200px;
    height: 200px;
    background-color: #f0f0f0;
    border-radius: 50%;
    overflow: hidden;
}

.circle-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.testimonial_client_name {
    position: absolute;
    top: 230px;
    left: 90px;
    font-weight: 700;
    font-size: 20px;
    font-family: Inter var, sans-serif;
}

.testimonial_client_name span:last-of-type {
    display: block;
    margin-left: -20px;
    font-size: 15px;
    font-style: italic;
}

.circule_columns {
    position: absolute;
    color: #0957ad;
    top: -5px;
    font-size: 3em;
    left: 230px;
    width: 1.2em;
}


.client_testimonial_reviews {
    z-index: 1;
    position: absolute;
    top: 420px;
    left: 250px;
    width: 1030px;
    height: 300px;
    background-color: white;
    border: 2px solid #0957ad;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.client_testimonial_reviews_first_child{
    position: absolute;
    top: 20px;
    left: 250px;
    font-size: 20px;
    font-family: Inter var, sans-serif;
}

.client_testimonial_reviews_first_child span:nth-of-type(2){
    display: block;
    text-align: justify;
    word-wrap: break-word;
    width: 700px;
    font-size: 18px;
}

.clients_review_star{
    position: absolute;
    top:55%;
    font-size: 40px;
}

.clients_review_star svg:nth-child(-n+3) {
    color: #FABE19;
}

.final_review_quotes{

    position: absolute;
    color: #0957ad;
    bottom: 15%;
    font-size: 3em;
    left: 90%;
    width: 1.2em;
}