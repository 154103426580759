

.talk_to_us_heading_section{
    position: absolute;
    top: 80px;
    font-size: 40px;
    font-weight: 700;
    font-family: Inter var, sans-serif;
}

.talk_to_us_button{
    font-style: italic;
    font-family: Inter var, sans-serif;
    position: absolute;
    padding: 10px 40px 10px 40px;
    top: 185px;
    font-family: Inter var, sans-serif;
    font-weight: 700;
    border-radius: 5px;
    border: 1px solid #0957ad;
    background-color: #0957ad;
    color: white;
}