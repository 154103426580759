.technical-service-banner {
    padding: 8%;
    background-color: #0957ad;
    text-align: center;
    font-size: 50px;
    text-transform: uppercase;
    color: white;
    font-family: Inter var, sans-serif;
    font-weight: 700;
}


.technical-service-main-section {
    overflow: hidden;
}

.techincal-service-row {
    padding: 50px 0px 60px 50px;
}

.technical_service_icon {
    border: 2px solid #0957ad;
    font-style: italic;
    text-align: center;
    font-size: 15px;
    padding: 2px;
    border-radius: 100px;
    background-color: white;
    color: #0957ad;
    font-weight: 700;
    width: 140px;
    margin-left: 6px;
    font-family: Inter var, sans-serif;
}

.technical-service-column-text span {
    font-family: Inter var, sans-serif;
    font-weight: 700;
    font-size: 35px;
    margin-left: -2px;
}

.technical-service-column-text p {
    font-size: 18px;
    width: 600px;
    text-align: justify;
}


.technical-service-main-section .clientsCounters{
    padding-bottom: 150px;
}