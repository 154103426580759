.nav-ul li {
  display: inline-block;
  padding-left: 20px;
}

.top-bar {
  background-color: #0957a2;
  color: white;
  padding: 15px;
}


a.nav-link.active {
  color: #0d589f !important;
  text-decoration: underline;
}

.fontawsome-clock {
  font-size: 17px;
  margin-right: 8px;
}

.navbar-container {
  background: #fff;
}

.bg-body-tertiary {
  background-color: transparent !important;
}

.navbar-container.container-fluid {
  padding: 0px 50px 0px 50px;
}

.top-bar.row {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-family: Inter var, sans-serif;
  padding: 20px 40px 20px 40px;
}

.ms-auto.navbar-nav {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-family: Inter var, sans-serif;
  gap: 40px;
}

a.nav-link {
  color: gray;
  font-weight: 700;
}

a.nav-link:hover {
  color: #0d589f;
  text-decoration: underline;
}

.contactButton {
  font-weight: 700;
  font-style: italic;
}



@media screen and (min-width: 978px) and (max-width: 1100px) {
  .top-bar.row {
    font-size: 14px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 12px;
  }
  button.contactButton.btn.btn-primary {
    font-size: 10px;
  }
  .ms-auto.navbar-nav {
    gap: 30px;
  }

  a.social-icon.socials-icons{
    height: 25px !important; 
    width: 25px !important;
}

.fontawsome-clock {
    font-size: 15px;
    margin-right: 8px;
  }
  
}

  
@media screen and (min-width: 680px) and (max-width: 975px) {
    .top-bar.row {
      font-size: 12px;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      font-size: 12px;
    }
    button.contactButton.btn.btn-primary {
      font-size: 10px;
    }
    .ms-auto.navbar-nav {
      gap: 20px;
    }
    
    a.social-icon.socials-icons{
        height: 25px !important; 
        width: 25px !important;
    }
    
    .fontawsome-clock {
        font-size: 15px;
        margin-right: 8px;
      }
  }


  @media screen and (min-width: 480px) and (max-width: 679px) {
    .top-bar.row {
      font-size: 12px;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      font-size: 12px;
    }
    button.contactButton.btn.btn-primary {
      font-size: 10px;
    }
    .ms-auto.navbar-nav {
      gap: 20px;
    }
    
    a.social-icon.socials-icons{
        height: 20px !important; 
        width: 20px !important;
        margin-right:4px !important;
    }
    
    .fontawsome-clock {
        font-size: 15px;
        margin-right: 8px;
      }
  }


  @media screen and (min-width: 200px) and (max-width: 478px) {
    .top-bar.row {
      font-size: 10px;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      font-size: 12px;
    }
    button.contactButton.btn.btn-primary {
      font-size: 10px;
    }
    .ms-auto.navbar-nav {
      gap: 10px;
    }
    
    a.social-icon.socials-icons{
        height: 16px !important; 
        width: 16px !important;
        margin-right:2px !important;
    }
    
    .fontawsome-clock {
        font-size: 15px;
        margin-right: 8px;
      }
  }


  img.d-inline-block.align-top {
    mix-blend-mode: multiply;
}