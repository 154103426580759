.portfolio_detail_main_image{
    position: relative;
   
}

.portfolio_detail_main_image img {
    width: 100%;
    height: 350px;
    object-fit: cover;
  }

  .portfolio_detail_main_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .portfolio_detail_main_overlay_text {
    color: white;
    font-size: 70px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }


  .portfolio_samples_icon{
    border: 2px solid #0957ad;
  font-style: italic;
  text-align: center;
  font-size: 15px;
  padding: 2px;
  border-radius: 100px;
  background-color: white;
  color: #0957ad;
  font-weight: 700;
  width: 110px;
  margin-left: 6px;
  font-family: Inter var, sans-serif;
  }


  .portfolio_samples{
    position: relative;
    padding: 80px 20px 0px 50px;
  }


  .portfolio_detail_process_icon{
    font-weight: 700;
    font-size: 30px;
    margin-top: 15px;
    font-family: Inter var, sans-serif;
  }


  .portfolio_detail_tab_set{
    color: #0957ad;
    background-color: transparent;
    font-size: 14px;
    padding: 7px 20px 7px 20px;
    border: 2px solid #6D767D;
    border-radius: 5px;
    font-weight: 700;
    font-family: Inter var, sans-serif;

  }


  .portfolio_detail_tab_set:hover{
    color: white;
    background-color: #0957ad;
    transition: 0.5s ease all;
  }

  .main_portfolio_detail_tab_set ul {
    padding: 0;
    list-style-type: none;
    text-align: center;
  }

  .main_portfolio_detail_tab_set li {
    display: inline-block;
    margin: 20px 56px 0px 0px ;
  }

 
  .portfolio_card{
    position: relative;
  }

  .card_square_box{
    position: absolute;
    border-radius: 10px;
    width: 80px;
    height: 12px;
    background-color: white;
    top: 6%;
    left: 30%;
  }

  .card_circle_box {
    position: absolute;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    background-color: white;
}
.circle1 {
    top: 6%;
    left: 70%;
    transform: translateX(-50%);
}
.circle2 {
    top: 6%;
    background-color: #6D767D;
    left: 73%;
    transform: translateX(-50%);
}
.circle3 {
    top: 6%;
    left: 76%;
    transform: translateX(-50%);
}

