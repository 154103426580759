.MiddleCard {
    padding: 100px 50px 80px 50px;
    margin: 0;
    background-color: #999FA4;
  }

  .middlecard_service_icon{
    border: 2px solid #0957ad;
    font-style: italic;
    text-align: center;
    font-size: 15px;
    padding: 2px;
    border-radius: 100px;
    background-color: white;
    width: 8.666667%;
    color: #0957ad;
    font-weight: 700;
    margin-left: 4px;
    font-family: Inter var, sans-serif;
  }

  .middlecard_service_shorts{
    margin-top: 10px;
  font-size: 25px;
  font-weight: 700;
  font-family: Inter var, sans-serif;
  }


  .first_middle_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .icon-container {
    border: 2px solid #0957ad; 
    border-radius: 10%; 
    padding: 10px 10px 10px 10px; 
  }

  .icon-container svg {
    color: #0957ad;
  }
  
  
  .icon-img {
    width: 50px; 
    height: 50px; 
    object-fit: cover; 
  }
  
  .centered {
    color: white;
    text-align: center;
    margin-top: 10px;
    font-family: Inter var, sans-serif;
  }

  .transparent-card {
    border-radius: 20px;
    margin-top:50px;
    background-color: transparent;
    border: none;
    padding: 20px 60px 20px 60px;
    border: 2px solid white;
    transition: background-color 0.5s ease;
  }
  
  .transparent-card:hover {
    background-color: #ffffff;
  }

  .transparent-card:hover svg {
    color: #ffffff;
  }


  .transparent-card:hover .centered {
    color: #0957ad; 
  }


  .transparent-card:hover .icon-container {
    background-color: #0957ad;
  }


  .transparent-card.active.card {
    background-color: white;
}


.transparent-card.active.card .centered {
  color: #0957ad; 
}

.transparent-card.active.card .icon-container {
  background-color: #0957ad;
}


.transparent-card.active.card svg {
  color: #ffffff;
}