.shortIntro {
  padding: 100px 50px 0px 50px;
  margin: 0;
}

.about_us_icon {
  border: 2px solid #0957ad;
  font-style: italic;
  text-align: center;
  font-size: 15px;
  padding: 2px;
  border-radius: 100px;
  width: 8.666667%;
  color: #0957ad;
  font-weight: 700;
  margin-left: 4px;
  font-family: Inter var, sans-serif;
}

.shortIntros {
  margin-top: 10px;
  font-size: 25px;
  font-weight: 700;
  font-family: Inter var, sans-serif;
}

.shortpart1 {
  font-size: 16px;
  width: 30.333333%;
  margin-left: 5%;
  font-family: Inter var, sans-serif;
  text-align: justify;
}

.shortpart2 {
  font-size: 16px;
  margin-left: 5%;
  width: 30.333333%;
  font-family: Inter var, sans-serif;
  text-align: justify;
}

.firstCard {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.secondCard {
    display: flex;
    align-items: center;
    font-size: 15px;
  }

  .thirdCard{
    display: flex;
    align-items: center;
    font-size: 15px;
}


.icon-container1{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 74px;
    height: 50px;
  font-family: Inter var, sans-serif;
  border-radius: 50%;
  background-color: #0957ad;
  margin-right: 10px;
  font-weight: 400;
}

.icon-container2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 74px;
    height: 50px;
    border-radius: 50%;
    font-family: Inter var, sans-serif;
    background-color: #6d7c6d;
    margin-right: 10px;
    font-weight: 400;
  }

  .icon-container3{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 50px;
    border-radius: 50%;
    font-family: Inter var, sans-serif;
    font-weight: 400;
    background-color: #0957ad;
    margin-right: 10px;
  }

  .icon-container2 svg {
    width: 25px;
    height: 25px;
    fill: white;
  }

.icon-container1 svg {
  width: 35px;
  height: 35px;
  fill: white;
}

.icon-container3 svg {
    width: 30px;
    height: 30px;
    fill: white;
  }

.firstCardCol {
  width: 28%;
}

.secondCardCol{
    width: 30%;
    margin-left: 35px;
}


@media screen and (min-width: 1314) and (max-width: 1419) {

}

.thirdCardCol{
    width: 32%;
    margin-left: 40px;
}


.slidersection {
  display: flex;
  position: relative;
  margin-top: 5%;
}


.slider-container{
  position: absolute;
  left: 217px;
  top:-100px
}

.right-image{
  margin-left: 20%;
}

.image-container {
  border-radius: 10px;
  border: 5px solid #fff;
  overflow: hidden;
}

.image-container img{
  object-fit: cover;
}


.slidersection .slick-prev:before, .slidersection .slick-next:before {
  font-size: 40px !important;
  color: #092d9c  !important;
}


.slidersection .slick-prev {
  left: -120px !important;
  top: 49% !important;
}

.slidersection .slick-prev, .slidersection
.slick-next {
  left: 109%;
  top: 49% !important;
}

.slidersection .slick-dots li button:before {
  font-family: 'slick';
  font-size: 37px !important;
  color: #092d9c !important;
}

.slidersection .slick-dots {
  bottom: 89px !important;
  left: 20px !important;
  height: 50px !important;
 
}

.slidersection .slick-dots li {
  margin: 0 25px !important;
}



.slidersection .slick-dots li button:before {
  content: '' !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px !important;
  height: 12px !important;
  border-radius: 6px !important;
  background-color: #092d9c;
  border-radius: 0;
}
