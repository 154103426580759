.blog_main_section {
    overflow: hidden;
}

.blog_main_image {
    position: relative;

}

.blog_main_image img {
    width: 100%;
    height: 350px;
    object-fit: cover;
}

.blog_main_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog_main_overlay_text {
    color: white;
    font-size: 70px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}

.blog-jobs-section {
    padding: 50px 50px 0px 82px;
}

.blog-icons {
    border: 2px solid #0957ad;
    font-style: italic;
    text-align: center;
    font-size: 15px;
    padding: 2px;
    border-radius: 100px;
    background-color: white;
    color: #0957ad;
    font-weight: 700;
    width: 90px;
    margin-left: 6px;
    font-family: Inter var, sans-serif;
}

.blog_service_shorts {
    margin-top: 10px;
    font-size: 30px;
    font-weight: 700;
    font-family: Inter var, sans-serif;
}

.blog_service_shorts span:last-of-type {
    margin-top: -10px;
    display: block
}

.blog_service_shorts_discription {
    font-size: 15px;
    font-family: Inter var, sans-serif;
    margin-top: 10px;
}

.blog-card-section {
    margin-top: 70px;
    background-color: #f8f8f8;
    width: 100%;
    height: 800px;
}


.first_blog_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    border: none;
}

.first-cards-bloger:hover{
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.5);
}

.blog-container-card {
    text-align: center;
}

.blog-container-card span b {
    font-size: 25px;
}


.blog-card-section .row {
    padding: 40px 0px 0px 30px;
}

.blog-read-more-button {
    
    padding: 8px 20px 8px 20px;
    border-radius: 5px;
    border: 1px solid #6D767d;
    color: white;
    background-color: #0957ad;
    font-style: italic;
}


.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog-show-more-button {
    margin-top: 40px;
    padding: 8px 30px 8px 30px;
    border-radius: 5px;
    border: 1px solid #6D767d;
    color: white;
    background-color: #0957ad;
    font-style: italic;
}

.first-cards-bloger{
    width: 90%;
}