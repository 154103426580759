.main-footer{
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #0957a2;
    padding: 150px 50px 30px 50px;
}

img.d-inline-block {
    mix-blend-mode: lighten;
}

.text-white.footerLinks.col-md-2 {
    margin-top: 55px;
    text-transform: uppercase;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    font-family: Inter var, sans-serif
}

span.footerLinks{
    margin-top: 55px;
    text-transform: uppercase;
    font-weight: 700;
    font-style: normal;
    display: block;
    font-size: 16px;
    font-family: Inter var, sans-serif
}


p.footerLinks {
    margin-top: 55px;
    text-transform: uppercase;
    font-weight: 700;
    font-style: normal;
    display: block;
    font-size: 16px;
    font-family: Inter var, sans-serif
}

ul li a {
    color: white;
    text-decoration: none;
}

ul li{
    margin-top: 12px;
}

.copy_right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    font-weight: 400;
    padding: 10px 50px 10px 50px;
}

.copy_right span {
    flex: 1;
}