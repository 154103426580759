.graphic-service-banner{
    padding: 8%;
    background-color: #0957ad;
    text-align: center;
    font-size: 50px;
    text-transform: uppercase;
    color: white;
    font-family: Inter var, sans-serif;
    font-weight: 700;
}



.graphic-service-main-section .clientsCounters{
    padding-bottom: 150px;
}


.graphic-service-main-section{
    overflow: hidden;
}

.technical-service-column-image img{
    width: 600px;
}