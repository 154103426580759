.about_main_image{
    position: relative;
   
}

.about_main_image img {
    width: 100%;
    height: 350px;
    object-fit: cover;
  }

  .about_main_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .about_main_overlay_text {
    color: white;
    font-size: 70px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }


  .about_mission_vision_section{
    overflow: hidden;
    position: relative;
    padding: 50px 0px 0px 0px;
  }

  .about_mission_vision_section img{
    width: 100%;
    object-fit: cover;
  }

  .about_mission_vision_icon{
    position: absolute;
    top: 150px;
    left: 60px;
    border: 2px solid #0957ad;
    font-style: italic;
    text-align: center;
    font-size: 13px;
    padding: 2px;
    border-radius: 100px;
    background-color: white;
    width: 9%;
    color: #0957ad;
    font-weight: 700;
    margin-left: 4px;
    font-family: Inter var, sans-serif;
  }

  .about_mission_vision_titles{
    position: absolute;
    top: 180px;
    left: 55px;
    font-size: 25px;
    font-weight: 700;
    font-family: Inter var, sans-serif;
  }

  .mission_vision_first_card{
    position: absolute;
    top: 350px;
    left: 4.5%;
    width: 40%;
    height: 30%;
  }

  .mission_vision_second_card{
    position: absolute;
    top: 350px;
    left: 55%;
    width: 40%;
    height: 30%;
  }

  .eye_div{
    position: absolute;
    top: -35px;
    left: 30px;
    padding: 20px;
    background-color: #0957ad;
    border-radius: 5px;
  }

  .eye_div svg{
   font-size: 35px;
   color: white;
  }
  .mision_vision_discripion{
    margin-top: 40px;
    margin-left: 15px;
    text-align: justify;
    font-family: Inter var, sans-serif;
  }

  .mision_vision_discripion span:last-of-type{
    font-size: 14px;
  }


  .mision_vision_discripion span:nth-of-type(1){
    font-size: 20px;
  }
 