.project_detail_main_section{
    overflow: hidden;
}

.project_detail_main_image{
    position: relative;
}

.project_detail_main_image img {
    width: 100%;
    height: 350px;
    object-fit: cover;
  }

  .project_detail_main_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .project_detail_main_overlay_text {
    color: white;
    font-size: 70px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }

  .project_name_heading {
    padding: 15px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.square-box {
    border-radius: 10px;
    position: relative;
    width: 90%;
    left: -25px;
    height: 100px; 
    background-color:#0957ad;
    border: 1px solid #6D767D;
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered-text {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    font-family: Inter var, sans-serif;
    position: absolute;
    left: 20px;
    color: #fff;
}

.bottom-right-button {
    padding: 5px 10px 5px 10px;
    font-size: 14px;
    font-weight: 700;
    font-family: Inter var, sans-serif;
    font-style: italic;
    color: #0957ad;
    border-radius: 5px;
    border: 1px solid #6D767D;
    position: absolute;
    right: 20px;
}

.project-full-detail-view {
    margin-top: -50px;
    background-image: url('../../../public/images/layer.png');
    height: 550vh;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    padding: 20px;
  }

  .images-container {
    position: relative;
    flex: 1;
    margin-right: 20px;
  }
  
  .right-project-image{
    position: absolute;
    top: 4.5%;
    left:20%;
    width: 70%;
    height: auto;
    object-fit: cover;
    display: block;
  }

  .project-image {
    position: absolute;
    top: 4%;
    left: 2%;
    width: 100%;
    height: auto;
    display: block;
  }
  
  .details-container {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .project-details{
    position: absolute;
    top: 5%;
    left: 23%;
  }


  .project-details span{
    margin-left:24% ;
    text-align: center;
    font-weight: 700;
    color: #0957ad;
    font-size: 25px;
  }

  .project-details p strong{
    color: #0957ad;
    font-family: Inter var, sans-serif;
    font-size: 17px;
    font-style: normal;
  }

  .project-details p{
    font-style: italic;
    font-family: Inter var, sans-serif;
  }

  .project-details .discription-detail{
    word-wrap: break-word;
    font-family: Inter var, sans-serif;
    width: 450px;
    text-align: justify;
    font-size: 15px;
  }

  .process-steps{
    position: relative;
    font-family: Inter var, sans-serif;
    top: 17%;
    left: 23%;
  }

  .process-steps span{
    margin-left:150px ;
    font-weight: 700;
    color: #0957ad;
    font-size: 25px;
  }

  .process-steps .step1{
    position: absolute;
    top: 120px;
    left: 52px;
    word-wrap: break-word;
    width: 400px;
  }

  
  .process-steps .step2{
    position: absolute;
    top: 350px;
    left: 52px;
    word-wrap: break-word;
    width: 400px;
  }

  .process-steps .step3{
    position: absolute;
    top: 570px;
    left: 52px;
    word-wrap: break-word;
    width: 400px;
  }

  .process-steps .step4{
    position: absolute;
    top: 790px;
    left: 52px;
    word-wrap: break-word;
    width: 400px;
  }


  .process-steps .step5{
    position: absolute;
    top: 1010px;
    left: 52px;
    word-wrap: break-word;
    width: 400px;
  }
  
  .process-steps .step6{
    position: absolute;
    top: 1240px;
    left: 52px;
    word-wrap: break-word;
    width: 400px;
  }
  

  .process-steps .step6{
    position: absolute;
    top: 1240px;
    left: 52px;
    word-wrap: break-word;
    width: 400px;
  }

  .process-steps .step7{
    position: absolute;
    top: 1460px;
    left: 52px;
    word-wrap: break-word;
    width: 400px;
  }