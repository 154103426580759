
.testing {
	display: flex;
	justify-content: center;
	align-items: center;
    padding-bottom: 108px;
}

.testing > .triangle  {
    width: 217px;
    height: 216px;
    margin: 150px auto 0px;
    position: relative;
    background-color: white;
    border-width: 0px 6px 6px 0px;
    margin: auto;
}

.testing > .triangle::before {
    content: "";
    position: absolute;
    top: calc(-164px / 2);
    left: calc((100% - 169px - -12px) / 2);
    width: 156px;
    height: 156px;
    background-color: #b7b7b7;
    transform: rotate(45deg);
    transform-origin: 50% 50%;
    border-width: 0px 6px 6px 0px;
    margin: auto;
}


.teamintroAbout {
    position: absolute;
    top: 60%;
    z-index: 1;
    font-family: Inter var, sans-serif;
    font-weight: 400;
    color: #0957ad;
    word-break: break-word;
    max-width: 200px;
    text-align: -webkit-center;
}

.triangular-img {
        clip-path: polygon(0% -236%, 0% 72%, 50% 100%, 162% 18%);
}


.teamintroimg{
    position: absolute;
    padding: 6px 15px;
    bottom: 210px;
}

.testing > .triangles2::before {
    content: "";
    position: absolute;
    top: calc(678px / 2);
    left: calc((100% - 270px - -12px) / 2);
    width: 156px;
    height: 150px;
    background-color: white;
    transform: rotate(45deg);
    transform-origin: 50% 50%;
    border-width: 0px 6px 6px 0px;
    margin: auto;
}

.testing > .triangles1::before {
    content: "";
    position: absolute;
    top: calc(678px / 2);
    left: calc((100% - 995px - -12px) / 2);
    width: 156px;
    height: 150px;
    background-color: white;
    transform: rotate(45deg);
    transform-origin: 50% 50%;
    border-width: 0px 6px 6px 0px;
    margin: auto;
}

.testing > .triangles3::before {
    content: "";
      position: absolute;
      top: calc(678px / 2);
      left: calc((100% - 200px - -668px) / 2);
      width: 156px;
      height: 150px;
      background-color: white;
      transform: rotate(45deg);
      transform-origin: 50% 50%;
      border-width: 0px 6px 6px 0px;
      margin: auto;
  }

.TeamIntro{
    position: relative;
}

.TeamintroCarad{
    position:absolute;
    top: 30%;
    left: 10%;
}

.testing {
    margin-right:100px;
}

.TeamintroCarad ul.slick-dots {
    display:none!important;
}

.TeamintroCarad .slick-prev:before,.TeamintroCarad .slick-next:before {
    font-size: 40px !important;
    color: white  !important;
  }
  
  
  .TeamintroCarad .slick-prev {
    left: 1200px !important;
  }

  .TeamintroCarad .slick-next {
    left: 1250px !important;
  }

  .TeamintroCarad .slick-prev,.TeamintroCarad .slick-next {
    top: 100% !important;
  }

  .telantedteam{
    position: absolute;
    top: 5%;
    left: 4%;
  }

  .telantedteams_icon {
    border: 2px solid #0957ad;
    font-style: italic;
    text-align: center;
    font-size: 15px;
    padding: 2px;
    border-radius: 100px;
    background-color: white;
    color: #0957ad;
    font-weight: 700;
    width: 121px;
    margin-left: 6px;
    font-family: Inter var, sans-serif;
}

.sad{
    font-weight: 700;
    font-size: 26px;
    font-family: Inter var, sans-serif;
}