.uidesign-service-banner{
    padding: 8%;
    background-color: #0957ad;
    text-align: center;
    font-size: 50px;
    text-transform: uppercase;
    color: white;
    font-family: Inter var, sans-serif;
    font-weight: 700;
}

.uidesign-service-main-section{
    overflow: hidden;
}

.uidesign-service-main-section .clientsCounters{
    padding-bottom: 150px;
}
