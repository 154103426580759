.main_portfolio {
  padding: 100px 50px 0px 50px;
  margin: 0;
}

.portfolio_icons {
  border: 2px solid #0957ad;
  font-style: italic;
  text-align: center;
  font-size: 15px;
  padding: 2px;
  border-radius: 100px;
  background-color: white;
  color: #0957ad;
  font-weight: 700;
  width: 121px;
  margin-left: 6px;
  font-family: Inter var, sans-serif;
}

.portfolio_icon {
  font-weight: 700;
  font-size: 26px;
  margin-top: 15px;
  font-family: Inter var, sans-serif;
}


.portfolio_card {
  margin-bottom: 50px;
  margin-left: 14px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.5) 5px 5px 10px;
  border: 2px solid #d1d1d1;
}

.portfolio_card .slick-slide {
  transition: all 0.3s ease;
}

.portfolio_card .slick-slide img {
  background-color: #1b1b1a;
  border: 1px solid black;
  border-top: 30px solid  #0957a2;
  width: 230px;
  margin-left: -100px;
  border-radius: 11px;
  margin-top: 20px;
  padding: 10px;
 z-index: 1;
 opacity: 0.7;
}

.portfolio_card .slick-center img {
  background-color: #1b1b1a;
  border: 1px solid black;
  border-top: 30px solid  #0957a2;
  padding: 22px 60px 0px 60px;
  margin-left: 115px;
  width: 350px;
  border-radius: 12px;
  margin-top: 20px;
  opacity: 1;
  z-index: 9;
}


.portfolio_card .slick-slide:not(.slick-center) {
  opacity: 0.2;
  z-index: 1;
}

.portfolio_card .slick-center {
  opacity: 1;
  z-index: 9;
}

.portfolio_card .slick-prev,
.portfolio_card .slick-next {
  z-index: 1;
  color: white;
  cursor: pointer;
}

.portfolio_card .slick-prev {
  left: 190px;
  /* Adjust the horizontal position for the previous button */
}

.portfolio_card .slick-next {
  right: 160px;
  /* Adjust the horizontal position for the next button */
}



.portfolio_card1{
  padding: 10px;
  position: relative;
}


.portfolio_card1text{
  font-size: 20px;
  color: #0957ad;
  font-weight: 700;
  font-family: Inter var, sans-serif;
}

.portfolio_card1_button{
  position: absolute;
  top: 10px;
  left: 70%;
}

.portfolio_card1_button button{
  border-radius: 5px;
  font-family: Inter var, sans-serif;
  font-size: 17px;
  color: white;
  border: 1px solid #0957a2;
  background-color: #0957a2;
  font-style: italic;
  padding: 10px 20px 10px 20px;
}


.portfolioShowMoreButtonContainer{
  display: flex;
  justify-content: center; 
  align-items: center;
}

.portfolioShowMoreButton{
  border-radius: 5px;
  font-family: Inter var, sans-serif;
  font-size: 17px;
  color: white;
  border: 1px solid #0957a2;
  background-color: #0957a2;
  font-style: italic;
  padding: 15px 40px 8px 40px;
  align-items: center;
}