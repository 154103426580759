.contact_us_main_image{
    position: relative;
   
}

.contact_us_main_image img {
    width: 100%;
    height: 350px;
    object-fit: cover;
  }

  .contact_us_main_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .contact_us_main_overlay_text {
    color: white;
    font-size: 70px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }


  .contact-us-jobs-section {
    padding: 50px 50px 0px 82px;
  }

  .contact-us-icons{
    border: 2px solid #0957ad;
    font-style: italic;
    text-align: center;
    font-size: 15px;
    padding: 2px;
    border-radius: 100px;
    background-color: white;
    color: #0957ad;
    font-weight: 700;
    width: 130px;
    margin-left: 6px;
    font-family: Inter var, sans-serif;
  }


  .contact_us_service_shorts {
    margin-top: 10px;
    font-size: 30px;
    font-weight: 700;
    font-family: Inter var, sans-serif;
  }

  .contact_us_service_shorts span:nth-last-of-type(2){
    font-size: 32px;
  }
  
  .contact_us_service_shorts span:last-of-type {
    margin-top: -10px;
    display: block
  }

  .contact_us_service_shorts_discription{
    font-size: 15px;
    font-family: Inter var, sans-serif;
    margin-top: 14px;
  }


  .contact_us_form_image{
    position: relative;
    padding: 80px 0px 0px 70px;
  }

  .contact_us_image_detail{
    position: absolute;
    top: 180px;
    font-size: 18px;
    color: white;
    left: 130px;
  }

  .contact_us_form_fields{
    position: relative;
    top: -560px;
    left: 640px;
  }

  .contact-us-apply-form-button button{
    position: absolute;
    top: 400px;
    left: 250px;
    width: 150px;
  }


  .contact_us_main_section{
    overflow: hidden;
  }


  .contact-us-message{
    position: absolute;
    top: 170px;
  }

  .contact-us-message textarea{
width: 712px;
resize: none;
  }